import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'leaflet/dist/leaflet.css';
import 'assets/css/index.css';
import 'swiper/css/swiper.min.css';
import 'aos/dist/aos.css';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link rel="shortcut icon" type='image/x-icon' href={require('assets/favicon-16x16.ico')} />
        <meta name="theme-color" content="#ffffff" />
        <meta
        name="description"
        content="Mit unseren modernen und zukunftsorientierten digitalen Werbeflächen bieten wir Unternehmen die Möglichkeit, in gut besuchten Lokalen Ihre Werbespots zu schalten und somit viele potentielle Interessenten auf Ihr Angebot, Ihr Start-Up oder Ihr neues Produkt aufmerksam zu machen."
        />
        <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={require('assets/images/location/derbarber/derbarber1@2x.jpg')} />
        <meta
        property="og:title"
        content="Tenbi | Digitale Plakatwerbung für jeden."
        />
        <meta
          property="og:description"
          content="Mit unseren modernen und zukunftsorientierten digitalen Werbeflächen bieten wir Unternehmen die Möglichkeit, in gut besuchten Lokalen Ihre Werbespots zu schalten und somit viele potentielle Interessenten auf Ihr Angebot, Ihr Start-Up oder Ihr neues Produkt aufmerksam zu machen."
        />
        <meta property="og:url" content="https://www.tenbi.de" />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap"
          rel="stylesheet"
        />
        <script
          src="https://kit.fontawesome.com/4c273e6d43.js"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://www.tenbi.de/assets/js/matomoTrackingCode.js"
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      {props.children}
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
